import isUndefined from 'lodash/isUndefined';
import { compose, prop, path, mergeAll, trim, length } from 'ramda';
import { createSelector } from 'reselect';

import { selectQueryParams } from '../App/selectors';

import { Feature } from './FeaturesSurveyStep/constants';
import { CompanyForm } from './ProfileStep/CompanyFormSelector/companyForm';
import { CompanySize } from './ProfileStep/CompanySizeSelector/companySize';
import { STEP_EMAIL, STEP_REGION, STEP_FEATURES, STEP_SUBMITTING, STEPS_ORDER } from './constants';
import { ROUTE_SIGNUP_CAMPAIGN, ROUTE_SIGNUP_EXTERNAL, ROUTE_SIGNUP_PARTNER, ROUTE_SIGNUP_SANDBOX } from './routes';
import { PartnerType } from './types/partnerType';
import { sanitizeParameter } from './util';

const selectLocation = (state) => state.location;

const selectLocationType = createSelector([selectLocation], (location) => location.type);
const selectLocationPayload = createSelector([selectLocation], (location) => location.payload);
export const selectLocationQuery = createSelector([selectLocation], (location) => location.query);

export const selectEmailParameter = createSelector([selectQueryParams], (queryParams) => queryParams?.email);
export const selectCompanyFormParameter = createSelector([selectQueryParams], (queryParams) =>
  sanitizeParameter(queryParams?.company_form, Object.values(CompanyForm)),
);
export const selectCompanySizeParameter = createSelector([selectQueryParams], (queryParams) =>
  sanitizeParameter(queryParams?.company_size, Object.values(CompanySize)),
);
export const selectSignupFeaturesParameter = createSelector([selectQueryParams], (queryParams) =>
  sanitizeParameter(queryParams?.signup_features, Object.values(Feature), true),
);

export const selectIsSandboxed = createSelector(
  [selectLocationType],
  (locationType) => locationType === ROUTE_SIGNUP_SANDBOX,
);
export const selectIsExternal = createSelector(
  [selectLocationType],
  (locationType) => locationType === ROUTE_SIGNUP_EXTERNAL,
);

export const selectCampaign = createSelector([selectLocationPayload], (locationPayload) => locationPayload.identifier);
export const selectHasCampaign = createSelector(
  [selectLocationType, selectLocationPayload],
  (locationType, locationPayload) => locationType === ROUTE_SIGNUP_CAMPAIGN && !isUndefined(locationPayload.identifier),
);

export const selectPartner = createSelector([selectLocationPayload], (locationPayload) => {
  const type = locationPayload.type;
  const id = locationPayload.identifier;

  if (!isUndefined(id) && !isNaN(parseInt(id)) && Object.values(PartnerType).includes(type)) {
    return { type, id: parseInt(id) };
  }
  return undefined;
});
export const selectHasPartner = createSelector(
  [selectLocationType, selectPartner],
  (locationType, partner) => locationType === ROUTE_SIGNUP_PARTNER && !isUndefined(partner),
);

export const selectShouldSkipFeaturesStep = createSelector(
  [selectIsExternal, selectHasPartner, selectSignupFeaturesParameter],
  (isExternal, hasPartner, signupFeaturesParameter) =>
    (isExternal || hasPartner) && !isUndefined(signupFeaturesParameter),
);

export const selectEmailValidation = (state) => state.signup.emailValidation;

export const selectSignupFailed = path(['signup', 'signupFailed']);

export const selectSteps = path(['signup', 'steps']);

export const selectFormValues = createSelector(selectSteps, (steps) => mergeAll(Object.values(steps)));

export const selectShouldIndicateFirstStep = path(['signup', 'indicateFirstStep']);

export const selectStepsOrder = () => STEPS_ORDER;

export const selectStepIndex = path(['signup', 'stepIndex']);

const selectStepIndicatorSteps = createSelector(
  selectShouldIndicateFirstStep,
  selectStepsOrder,
  (shouldIndicateFirstStep, orderedSteps) => {
    const steps = orderedSteps.filter((stepName) => stepName !== STEP_SUBMITTING);

    if (shouldIndicateFirstStep) {
      return steps;
    }

    return steps.slice(1);
  },
);

export const selectTotalSteps = createSelector(selectStepIndicatorSteps, length);

export const selectMaxStepIndexSeen = path(['signup', 'maxStepIndexSeen']);

export const selectIsSigningUp = createSelector(
  selectStepIndex,
  selectStepsOrder,
  (stepIndex, stepsOrder) => stepIndex === stepsOrder.indexOf(STEP_SUBMITTING),
);

export const selectEmail = createSelector(selectSteps, compose(trim, prop('email'), prop(STEP_EMAIL)));

export const selectCurrentStep = createSelector(
  selectStepIndex,
  selectStepsOrder,
  selectSteps,
  (stepIndex, stepsOrder, stepsState) => {
    const stepName = stepsOrder[stepIndex];
    return stepsState[stepName];
  },
);

export const selectReferralToken = path(['signup', 'referralToken']);

export const selectIdentityProviderToken = path(['signup', 'identityProvider', 'token']);

export const selectIdentityProviderError = path(['signup', 'identityProvider', 'error']);

export const selectSignupFeatures = path(['signup', 'steps', STEP_FEATURES, 'signupFeatures']);

export const selectLanguage = path(['signup', 'steps', STEP_REGION, 'language']);

export const selectHasPlanningBoosterEnabled = path(['signup', 'planningBoosterEnabled']);
